import { Validator } from 'simple-vue-validator';
// let url;
// let temp = function () {
//     if (Validator.isEmpty(url)){
//         return;
//     }
//     const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
//     const matches = url.match(p);
//     if(matches){
//         return;
//     }
//     return Vue.i18n.translate('errors.youtube.invalid');
// }
// export default function propNameExistValidation(url_) {
//     url = url_;
//     return temp;
// };

export default class CheckYoutubeUrl {
  constructor(youtubeUrl, $i18n) {
    this.youtubeUrl = youtubeUrl;
    this.$i18n = $i18n;
  }

  get isValidFunc() {
    const validationFunc = async () => {
      if (Validator.isEmpty(this.youtubeUrl)) {
        return;
      }
      const p =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      const matches = this.youtubeUrl.match(p);
      if (matches) {
        return;
      }
      return this.$i18n.t('errors.youtube.invalid');
    };

    return validationFunc;
  }
}
